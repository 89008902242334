<template>
    <el-scrollbar class="paper-wrapper" style="height: 100%" :native="false">
        <div class="paper-container">
            <div class="paper-title">{{paperTitle}}</div>
            <div class="paper-content" v-if="paperContent">
<!--                v-if="questionItem.content"-->
                <div class="question-type-item" v-for="(questionItem, questionKey, questionIndex) in paperContent">
                    <div class="question-type-title">
                        <span class="title">{{questionIndex + 1 | formatTestType}}、{{questionItem.type}}</span>
                        <span class="subtitle">(共{{questionItem.count}}题， 共{{questionItem.total_score}}分)</span>
                    </div>
                    <div class="subject-item" v-for="(subjectItem, subjectIndex) in questionItem.content">
                        <div class="subject-title">
                            <span>{{subjectIndex + 1}}.</span>
                            <span class="score">({{subjectItem.score}}分)</span>
                            <span class="title" v-html="subjectItem.topic.title"></span>
                        </div>
                        <el-radio-group v-model="subjectItem.topic.options.value" class="subject-answer" v-if="questionItem.type === '单选题' || questionItem.type === '判断题'">
                            <el-radio :label="optionIndex" class="answer-item" v-for="(optionItem, optionIndex) in subjectItem.topic.options.option">
                                {{optionIndex + 1 | formatNumber}}、{{optionItem.name}}
                            </el-radio>
                        </el-radio-group>
                        <el-checkbox-group v-model="subjectItem.topic.options.value" class="subject-answer" v-if="questionItem.type === '多选题'">
                            <el-checkbox :label="optionIndex"  class="answer-item" v-for="(optionItem, optionIndex) in subjectItem.topic.options.option">
                                {{optionIndex + 1 | formatNumber}}、{{optionItem.name}}
                            </el-checkbox>
                        </el-checkbox-group>
                        <div class="subject-answer" v-if="questionItem.type === '填空题' && isSubmit === 0">
                            <div class="textarea-box" v-for="(optionItem, optionIndex) in subjectItem.topic.options.option">
                                <span>{{optionIndex + 1 | formatNumber}}、</span>
                                <el-input v-model="subjectItem[`value_${optionIndex}`]" placeholder="请输入答案" style="width: 400px"></el-input>
                            </div>
                        </div>
                        <div class="subject-answer" v-if="questionItem.type === '填空题' && isSubmit === 1">
                            <div class="textarea-box" v-for="(optionItem, optionIndex) in subjectItem.topic.options.student_value">
                                <span>{{optionIndex + 1 | formatNumber}}、</span>
                                <el-input :value="optionItem" placeholder="请输入答案" style="width: 400px"></el-input>
                            </div>
                        </div>
                        <div class="subject-answer" v-if="questionItem.type === '问答题'">
                            <div class="textarea-box">
                                <el-input type="textarea" :rows="5" class="textarea-box" placeholder="请输入答案" style="width: 400px" v-model="subjectItem.topic.options.value" @input="change($event)"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="text-align: center; margin-top: 20px" v-if="role === 4">
                    <el-button type="primary" @click="submitBtn">提交</el-button>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import wsc from "../../utils/wsConfig";
    import {sendWsData} from "@/utils/utils";
    export default {
        filters: {
            formatNumber(nums) {
                const numbers = {
                    '1': 'A',
                    '2': 'B',
                    '3': 'C',
                    '4': 'D',
                    '5': 'E',
                    '6': 'F',
                }
                return numbers[nums]
            },
            formatTestType(type) {
                const types = {
                    1: '一',
                    2: '二',
                    3: '三',
                    4: '四',
                    5: '五',
                    6: '六',
                    7: '七',
                    8: '八',
                    9: '九',
                    10: '十',
                }
                return types[type]
            }
        },
        data() {
            return {
                role: Number(localStorage.getItem('role')),
                quiz_id: Number(this.$route.query.id) || '',
                isSubmit: Number(this.$route.query.isSubmit) || 0,
                teacher_id: Number(localStorage.getItem('sId')) || '',
                student_id: Number(localStorage.getItem('s_id')) || '',
                paperTitle: '',
                paperContent: {},
                ws: null,
                send_id: 0,
            }
        },
        mounted() {
            this.getPaperContent();

            if (this.role === 3) {
                this.send_id = this.teacher_id;
            } else if (this.role === 4) {
                this.send_id = this.student_id;
            }
            if (wsc.type === 1) {
                this.ws = new WebSocket('ws://' + wsc.url)
                this.ws.onopen = (evt) => {
                    let data = {
                        send_name: localStorage.getItem('sUsername'),
                        send_id: this.send_id,
                        send_role: this.role,
                        send_ico: localStorage.getItem('sIcon') || require('../../assets/images/user_img.png'),
                        c_id: Number(localStorage.getItem('c_id')),
                        action: 'init',
                        isquiz: true,
                    };

                    this.ws.send(JSON.stringify(data));
                }
            }
        },
        methods: {
            //在输入框改变事件中强制刷新
            change(e) {
                this.$forceUpdate();
            },
            getPaperContent() {
                let params = {
                    quiz_id: this.quiz_id
                }
                this.$shttp.axiosGetBy(this.$api.showPaperContent, params, (res) => {
                    if (res.code === 200) {
                        let paperContentTmp = res.data.data
                        this.paperContent = res.data.data
                        this.paperTitle = res.data.title
                        for (let key in paperContentTmp) {
                            let questionContent = paperContentTmp[key].content
                            for (let i = 0; i < questionContent.length; i++) {
                                if (questionContent[i].topic_type === 1 || questionContent[i].topic_type === 3 || questionContent[i].topic_type === 5) {
                                    // this.$set(questionContent[i].topic.options, 'value', '')
                                    if (questionContent[i].topic.hasOwnProperty('options')) {
                                        // questionContent[i].topic.options.value = ''
                                        this.$set(questionContent[i].topic.options, 'value', '')
                                    } else {
                                        questionContent[i].topic.options = {}
                                        // questionContent[i].topic.options.value = ''
                                        this.$set(questionContent[i].topic.options, 'value', '')
                                    }
                                    if (this.isSubmit === 1) {
                                        questionContent[i].topic.options.value = questionContent[i].topic.options.student_value
                                    }
                                } else if (questionContent[i].topic_type === 2) {
                                    this.$set(questionContent[i].topic.options, 'value', [])
                                    // questionContent[i].topic.options.value = []
                                    if (this.isSubmit === 1) {
                                        questionContent[i].topic.options.value = questionContent[i].topic.options.student_value
                                    }
                                } else if (questionContent[i].topic_type === 4) {
                                    if (questionContent[i].topic.hasOwnProperty('options')) {
                                        this.$set(questionContent[i].topic.options, 'value', [])
                                    } else {
                                        questionContent[i].topic.options = {}
                                        this.$set(questionContent[i].topic.options, 'value', [])
                                    }
                                    // questionContent[i].topic.options.value = []
                                    for (let j = 0; j < questionContent[i].topic.options.option; j++) {
                                        this.$set(questionContent[i], `value_${j}`, '')
                                    }
                                }
                            }
                        }
                        this.paperContent = paperContentTmp
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            sortNumber(a, b) {
                return a - b
            },
            submitBtn() {
                this.$confirm('是否要提交答案，提交之后无法再作答', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let paperContent = this.paperContent;
                    let studentAnswer = {};
                    for (let key in paperContent) {
                        studentAnswer[key] = paperContent[key].content.map(item => {
                            let obj = {
                                id: item.id
                            }
                            if (key === 'multiple') {
                                obj.value = item.topic.options.value.sort(this.sortNumber)
                            } else if (key === 'fillBlank') {
                                obj.value = [];
                                for (let i = 0; i < item.topic.options.option; i++) {
                                    obj.value.push(item[`value_${i}`])
                                }
                            } else {
                                obj.value = item.topic.options.value
                            }
                            return obj
                        })
                    }
                    let params = {
                        quiz_id: this.quiz_id,
                        stu_answer: JSON.stringify(studentAnswer),
                    }
                    this.$shttp.axiosPost(this.$api.studentSubmitTest, params, (res) => {
                        if (res.code === 200) {
                            if (wsc.type === 1) {
                                let sdata = {
                                    send_name: localStorage.getItem('sUsername'),
                                    c_id: Number(localStorage.getItem('c_id')),
                                    type: 'test_notice'
                                };
                                const msg = sendWsData("test_notice", sdata)
                                this.ws.send(msg)
                                this.ws.send(JSON.stringify(sdata));
                            }
                            this.$message.success(res.msg + '3秒后将自动关闭窗口');
                            setTimeout(() => {
                                window.close()
                            }, 3000)
                        } else {
                            this.$message.warning(res.msg + '3秒后将自动关闭窗口');
                            setTimeout(() => {
                                window.close()
                            }, 3000)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消提交')
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .paper-wrapper {
        width: 100%;
        height: 100%;
        /*background: #fff;*/
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .paper-container {
            width: 1200px;
            margin: 0 auto;
            background: #fff;
            padding: 20px;
            box-sizing: border-box;
            .paper-title {
                text-align: center;
                color: #409eff;
                font-size: 24px;
                font-weight: 500;
            }
            .paper-content {
                margin-top: 20px;
                .question-type-item {
                    margin-bottom: 20px;
                    .question-type-title {
                        .title {
                            color: #333;
                            font-size: 18px;
                            font-weight: 500;
                        }
                        .subtitle {
                            font-size: 16px;
                            color: #666;
                        }
                    }
                    .subject-item {
                        margin-top: 10px;
                        padding-bottom: 20px;
                        .subject-title {
                            color: #333;
                            font-size: 16px;
                            font-weight: 500;
                            .title {
                                ::v-deep p {
                                    margin: 0;
                                    display: inline;
                                }
                            }
                            .score {
                                color: #666;
                                opacity: 0.7;
                                margin: 0 10px;
                            }
                        }
                        .subject-answer {
                            display: block;
                            margin-top: 15px;
                            padding-left: 20px;
                            .answer-item {
                                margin-top: 6px;
                                display: flex;
                                ::v-deep .el-radio__label {
                                    width: 1%;
                                    flex: 1;
                                    white-space: normal;
                                    line-height: 1.3;
                                }
                            }
                            .textarea-box {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
